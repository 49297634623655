import * as React from 'react';

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../components/layout';
import Section from '../../components/section';
import useBlogData from '../../hooks/use-blog-data';

import BlogListItem from '../../components/blog/blog-list-item';

const htmlTitle = 'Atlassian Apps & Integrations Blog';
const pageTitle = 'Blog';
const description =
  'How-to guides, articles, tips and news about Atlassian Confluence & Jira, monday.com and beyond, documentation, analytics, project management best practices';

const BlogPage = () => {
  const nodes = useBlogData();
  return (
    <>
      <GatsbySeo
        language="en"
        title={htmlTitle}
        description={description}
        openGraph={{
          url: 'https://www.dsapps.dev/blog/',
        }}
      />
      <Layout pageTitle={htmlTitle}>
        <Section pageTitle={pageTitle}>
          <section>
            <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
              {nodes.map((node) => (
                <BlogListItem node={node} />
              ))}
            </div>
          </section>
        </Section>
      </Layout>
    </>
  );
};

export default BlogPage;
