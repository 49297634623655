import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BlogListItem = ({ node }) => {
  const { id, slug, excerpt } = node;
  // eslint-disable-next-line camelcase
  const { hero_image, hero_image_alt, date, title } = node.frontmatter;
  return (
    <div key={id} className="flex-1 w-33 ">
      <div className="w-full h-48">
        <Link
          to={`/blog/${slug}`}
          className="text-gray-900 dark:text-gray-100 animate-underline"
        >
          <GatsbyImage
            className="object-cover object-center w-full h-full rounded-lg"
            image={getImage(hero_image)}
            // eslint-disable-next-line camelcase
            alt={hero_image_alt}
          />
        </Link>
      </div>
      <div className="mt-6">
        <div className="text-sm text-gray-500 dark:text-gray-400 my-4">
          {date}
        </div>
        <h2 className="mb-2 text-xl font-medium text-gray-900 title-font">
          <Link
            to={`/blog/${slug}`}
            className="text-gray-900 dark:text-gray-100 animate-underline"
          >
            {title}
          </Link>
        </h2>
        <p className="leading-relaxed dark:text-gray-300">{excerpt}</p>
      </div>
    </div>
  );
};

export default BlogListItem;
